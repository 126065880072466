<template>
  <button
    type="button"
    class="rounded whitespace-nowrap text-xs sm:text-sm disabled:cursor-not-allowed flex"
    :disabled="disabled"
    :class="buttonClass"
  >
    <slot name="icon"></slot>
    <slot></slot>
  </button>
</template>

<script setup lang="ts">
import type { PropType } from "vue";
import { computed, useAttrs } from "vue";

const props = defineProps({
  centered: {
    type: Boolean,
    default: true
  },
  disabled: {
    type: Boolean,
    default: false
  },
  size: {
    type: String as PropType<"small" | "medium">,
    default: "medium"
  }
});

const attrs = useAttrs();

const buttonClass = computed(() => {
  let finalClass = "";

  if (props.centered) {
    finalClass +=
      " justify-center justify-items-center items-center text-center";
  } else {
    finalClass += " text-left";
  }

  if (props.size === "small") {
    finalClass += " px-3 py-1";
  } else {
    finalClass += " px-6 py-2-5";
  }

  const classNames = attrs.class as string;
  const hasFont = classNames && classNames.includes("font-");
  const fontClasses = hasFont ? ` ${classNames}` : ` font-medium ${classNames}`;
  const hasText = classNames && classNames.includes("text-");
  const textClasses = hasText ? ` ${classNames}` : ` text-white ${classNames}`;
  const hasDisabled = classNames && classNames.includes("disabled:");
  const disabledClasses = hasDisabled
    ? ` ${classNames}`
    : ` disabled:text-gray-400 disabled:opacity-50 ${classNames}`;
  return `${finalClass} ${fontClasses} ${textClasses} ${disabledClasses}`.trim();
});
</script>
